<template>
    <div class="header">
        <!-- 折叠按钮 -->
<!--      <div class="nullleft" :style="{width: leftwidth+'px'}">三阅</div>-->
      <div class="collapse-btn" @click="collapseChage">
          <i v-if="!collapse" class="el-icon-s-fold"></i>
          <i v-else class="el-icon-s-unfold"></i>
        </div>
        <div class="logo">图书馆集群管理系统</div>
        <div class="url">
        <div id="search" @click="redirectToURL" class="search" style="cursor: pointer">图书检索</div>
        <div  @click="dataToUrl" class="data" style="cursor: pointer">图书大数据</div>
        </div>

        <div class="header-right" id="user">

            <div class="header-user-con">
                <!-- 用户头像 -->
                <div class="user-avator">
                  <img
                      :src='image'
                      class="user-avator"
                      alt
                  />
                </div>
                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{username}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>
<script>

const opacUrl = "https://opac.sanyue100.com"
// const opacUrl = "http://192.168.6.197:8899"

const databoardUrl = "http://databoard.sanyue100.com"
// const databoardUrl = "http://192.168.6.173:8991/"

export default {
    data() {
        return {
          fullscreen: false,
          // message: 2,
          image:'',
          username:'',
          lib:'',
          token:''
        };
    },
  created(){
      this.image=localStorage.getItem("schoolimg")
      this.username=localStorage.getItem("username")
      this.lib = localStorage.getItem("libraryId")
      console.log(localStorage.getItem("libraryId"))
      console.log(this.token)
  },
  computed: {
        collapse() {
            return this.$store.state.collapse;
        }
    },
    methods: {
        //跳转
        redirectToURL() {
            var opac = window.open ( opacUrl,'_blank')
            if (opac){
                setTimeout(function () {
                    var libId = {libId: localStorage.getItem('libraryId')};
                    opac.postMessage(JSON.stringify(libId), opacUrl);
                },500);
            }
        },
        dataToUrl() {
            // eslint-disable-next-line no-unused-vars
            var jump = window.open(databoardUrl,"_blank")
            if (jump){
                setTimeout(function(){
                    var data = {token:localStorage.getItem('token')};
                    jump.postMessage(JSON.stringify(data), databoardUrl);
                },1000);
            }
        },
        // 用户名下拉菜单选择事件
        handleCommand(command) {
            if (command === "loginout") {
                localStorage.clear();
                sessionStorage.clear()
                this.$router.push("/login");
            }
        },
        // 侧边栏折叠
        collapseChage() {
            this.$store.commit("hadndleCollapse", !this.collapse);
        }
    },
    mounted() {
        if (document.body.clientWidth < 1500) {
            this.collapseChage();
        }
    }

};
</script>
<style scoped>

.url{
    display: flex;
    height: 100%;
    align-items: center;

}
.search{
 color: #3cb2ef;
}
.data{
    color: #3cb2ef;
    margin-left: 20px;
}
.header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    font-size: 16px;
    color:#787878;
    display: flex;
}
.collapse-btn {
    float: left;
    padding: 0 21px;
    cursor: pointer;
    line-height: 50px;
}
.header .logo {
    float: left;
    width: 250px;
    line-height: 50px;
}
.header .nullleft {
  /*float: left;*/
  /*line-height: 50px;*/
}
.header-right {
    /*float: right;*/
    /*width: 210px;*/
    /*display: flex;*/
    /*justify-content: space-around;*/
    /*align-items: center;*/
}
.header-user-con {
    //float: right;
    width: 210px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    position:absolute;
    right:0;
}
.btn-bell .el-icon-bell {
    color: #fff;
}
.user-name {
    margin-left: 30px;
}
.user-avator {
    margin-left: 50px;
}
.user-avator img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.el-dropdown-link {
    color: #222222;
    cursor: pointer;
}
.el-dropdown-menu__item {
    text-align: center;
}
</style>
